import { atom, selector } from 'recoil';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { SupportedLocale } from '../../supported';
import { apiClientState, currencyState, localeState, pricesTypeState, regionState } from '../appState';
import { CataloguePageProduct } from '../catalogue/catalogue';
import transformProduct from '../products/transformProduct';

export const homePageBestSellerProductsQuery = selector<CataloguePageProduct[]>({
  key: 'homePageBestSellerProductsQuery',
  get: ({ get }) => {
    const initial = get(homePageBestSellerProductsInitialsState);
    if (initial) return initial;

    return getBestSellerProducts(
      get(apiClientState),
      get(localeState),
      get(currencyState),
      undefined, // decimalPrecision
      undefined, // enabledDynamicRules
      get(pricesTypeState),
      get(regionState),
    );
  },
});

export const homePageBestSellerProductsInitialsState = GlobalStateRegister.register(
  atom<CataloguePageProduct[] | undefined>({
    key: 'homePageBestSellerProductsInitials',
    default: undefined,
  }),
);

export function getBestSellerProducts(
  apiClient: ApiClient,
  locale: SupportedLocale,
  currency: string,
  decimalPrecision: undefined | number,
  enabledDynamicRules: undefined | string[],
  pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign',
  region: undefined | string,
): Promise<CataloguePageProduct[]> {
  return apiClient
    .get<Omit<CataloguePageProduct, 'href'>[], {}>('/v1/homepage/best-seller/products', {
      currency,
      decimalPrecision,
      enabledDynamicRules,
      pricesType,
      region,
    })
    .then((r) => transformProduct(locale, r));
}
