import { atom, selector } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import { HomePageArticle } from './HomePageArticle';

export const homePageArticlesQuery = selector<HomePageArticle[]>({
  key: 'homePageArticlesQuery',
  get: ({ get }) => {
    const initials = get(homePageArticlesInitialsState);
    if (initials) return initials;

    return getHomePageArticlesList(get(apiClientState), get(localeState));
  },
});

export const homePageArticlesInitialsState = GlobalStateRegister.register(
  atom<HomePageArticle[] | undefined>({
    key: 'homePageArticlesInitials',
    default: undefined,
  }),
);

export const getHomePageArticlesList = (apiClient: ApiClient, locale: string): Promise<HomePageArticle[]> => {
  invariant(apiClient, "getHomePageArticles requres 'apiClient' to be defined");
  invariant(locale, "getHomePageArticles requres 'locale' to be defined");

  return apiClient.get<HomePageArticle[], {}>('/v1/homepage/contributions', { locale });
};
