import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../api/appState';
import { HomePageArticle } from '../../api/homePage/HomePageArticle';
import { routeUrl } from '../../routesBuilder';

export interface ArticleCardProps extends StandardDivProps<ArticleCardClassKey> {
  article: HomePageArticle;
  routeType?:
    | 'actionsDetail'
    | 'postsDetail'
    | 'termsAndConditionsDetail'
    | 'technicalAdvisorDetail'
    | 'tiresTestsDetail'
    | 'homePageClanky'
    | 'newsDetail';
}

export type ArticleCardClassKey = 'root' | 'container' | 'title' | 'description' | 'image';

const themeSettings = { name: 'ArticleCard' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ArticleCardClassKey, {}>({
      root: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
        width: 260,
        height: 370,
        border: '1px solid',
        borderColor: theme.palette.divider,
        justifySelf: 'center',
        cursor: 'pointer',
        transition: 'all .2s ease',

        '&:hover': {
          boxShadow: theme.shadows[3],
          textDecoration: 'none',

          '& $container': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          },
          '& $title, $description': {
            color: 'inherit',
          },
        },
      },
      container: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        transition: 'all .2s ease',
      },
      title: {
        marginBottom: theme.spacing(1),
        height: theme.spacing(9),
        fontSize: 17,
        overflow: 'hidden',
      },
      description: {
        height: 80,
        overflow: 'hidden',
        flexGrow: 1,
        fontSize: 14,
        color: theme.palette.text.secondary,
      },
      image: { width: '100%', height: 160 },
    }),
  themeSettings,
);

const ArticleCard = React.forwardRef<HTMLAnchorElement, ArticleCardProps>(
  ({ className, classes: pClasses, article, routeType = 'newsDetail' }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const to = routeUrl(routeType, { locale, params: { id: article.id } });

    return (
      <Link to={to} component={RouterLink} ref={ref} className={clsx(classes.root, className)} role='presentation'>
        <div className={classes.container}>
          <span className={classes.title}>{article.title}</span>
          <span className={classes.description}>{article.description}</span>
        </div>
        <img src={`${article.teaserImage}?width=350`} alt={article.title} className={classes.image} />
      </Link>
    );
  },
);

ArticleCard.displayName = 'ArticleCard';

export default withThemeProps(themeSettings)(ArticleCard);
