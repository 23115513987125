/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { homepageMainBannerQuery } from '../../../api/homePage/mainBanner';
import ProductListCarousel from '../../../components/ProductListCarousel';

export type HomePageMainBannerProps = StandardDivProps<HomePageMainBannerClassKey>;

export type HomePageMainBannerClassKey = 'root' | 'image';

const themeSettings = { name: 'HomePageMainBanner' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageMainBannerClassKey, {}>({
      root: {
        marginTop: theme.spacing(1),
        width: '100%',
        height: 0,
        paddingBottom: '16%',
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
          paddingBottom: '11%',
        },
        [theme.breakpoints.down('xs')]: {
          paddingBottom: '50%',
        },
      },
      image: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
      },
    }),
  themeSettings,
);

const HomePageMainBanner = React.forwardRef<HTMLDivElement, HomePageMainBannerProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const mainBannerLoadable = useRecoilValueLoadable(homepageMainBannerQuery);

    if (mainBannerLoadable.state === 'hasError') {
      console.error('HomePageMainBanner error:', mainBannerLoadable.contents);

      return <Alert severity='error'>Nastala chyba při načítání banneru</Alert>;
    }

    if (mainBannerLoadable.state === 'loading') {
      return <Skeleton width='100%' height={200} variant='rect' className={classes.image} />;
    }

    const banner = mainBannerLoadable.contents;

    if (!banner) {
      return <Box height={90} width={'100%'} />;
    }

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <ProductListCarousel>
          {banner.banners.map((b, idx) => (
            <div key={idx.toString()}>
              <LinkComponent to={b.href}>
                <img src={b.image} alt={b.title} title={b.title} className={classes.image} />
              </LinkComponent>
            </div>
          ))}
        </ProductListCarousel>
      </div>
    );
  },
);

const LinkComponent: React.FC<{ to: string }> = ({ children, to }) =>
  to && (to.toLowerCase().startsWith('https://') || to.toLowerCase().startsWith('http://')) ? (
    <a href={to} target='_blank' rel='noreferrer'>
      {children}
    </a>
  ) : (
    <Link to={to} component={RouterLink}>
      {children}
    </Link>
  );
HomePageMainBanner.displayName = 'HomePageMainBanner';

export default withThemeProps(themeSettings)(HomePageMainBanner);
