import { atom, selector } from 'recoil';
import invariant from 'tiny-invariant';
import { MediaImage, MediaStreamUrl } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import canUseDom from '../../@sprinx/react-after-razzle/canUseDom';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export interface HomePageVideoArticle {
  splashImage?: MediaImage;
  stream: MediaStreamUrl;
  // caption: string | TranslationDictionary;
  // timeSeconds?: number;
}

export const homepageVideoArticleQuerry = selector<HomePageVideoArticle | undefined>({
  key: 'homepageVideoArticleQuerry',
  get: ({ get }) => {
    if (!canUseDom) return undefined;
    return getHomepageVideoArticle(get(apiClientState)).then((r) => r || undefined);
  },
});

export const homePageVideoArticleState = GlobalStateRegister.register(
  atom<HomePageVideoArticle | null | undefined>({
    key: 'homePageArticleVideo',
    default: undefined,
  }),
);

export const getHomepageVideoArticle = (apiClient: ApiClient): Promise<HomePageVideoArticle | null> => {
  invariant(apiClient, "getHomePageVideoArticle(...) requires 'apiClient' parameter to be defined.");

  return apiClient.get<HomePageVideoArticle, {}>('/v1/homepage/contributions/video');
};
