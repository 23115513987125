import React from 'react';
import ErrorBoundary from '../ErrorBoundary';

export interface LoadableBoundaryProps {
  children?: React.ReactNode;

  errorFallback?: React.ReactElement;
  errorRenderFallback?: (props: { error: Error; reset: () => void }) => JSX.Element;
  onErrorReset?: () => void;

  suspenseFallback: React.ReactElement;
}

const LoadableBoundary: React.FC<LoadableBoundaryProps> = ({
  children = null,
  errorFallback,
  errorRenderFallback,
  onErrorReset,
  suspenseFallback,
}) => {
  return (
    <ErrorBoundary fallback={errorFallback} renderFallback={errorRenderFallback} onReset={onErrorReset}>
      <React.Suspense fallback={suspenseFallback}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};

LoadableBoundary.displayName = 'LoadableBoundary';

export default LoadableBoundary;
