import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { canUseDom } from '../../@sprinx/react-after-razzle';
import Page from '../../components/Page';
import HomePageNarrowContent from './components/HomePageNarrowContent';
import HomePageWideContent from './components/HomePageWideContent';

export type HomePageLayoutProps = StandardDivProps<HomePageLayoutClassKey>;

export type HomePageLayoutClassKey = 'root';

const themeSettings = { name: 'HomePageLayout' };
const useStyles = makeStyles(
  // (theme: Theme) =>
  createStyles<HomePageLayoutClassKey, {}>({
    root: {},
  }),
  themeSettings,
);

const HomePageLayout = React.forwardRef<HTMLDivElement, HomePageLayoutProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const theme = useTheme();
    const isWideB = useMediaQuery(theme.breakpoints.up('md'));
    const isWide = canUseDom ? isWideB : true;

    const pageTitle =
      'PRODEJ-PNEU.cz - pneuservis - autoservis - letní pneumatiky - zimní pneumatiky - nákladní pneumatiky - disky - Bridgestone - Barum - Continental - servis pneumatik - uskladnění pneumatik';
    return (
      <Page ref={ref} className={clsx(classes.root, className)} pageTitle={pageTitle}>
        {isWide ? <HomePageWideContent /> : <HomePageNarrowContent />}
      </Page>
    );
  },
);

HomePageLayout.displayName = 'HomePageLayout';

export default withThemeProps(themeSettings)(HomePageLayout);
