import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import { homePageBestSellerProductsQuery } from '../../../api/homePage/bestSellerProducts';
import LoadableBoundary from '../../../components/LoadableBoundary';
import ProductCard from '../../../components/ProductCard';
import HomePageHeadline from './HomePageHeadline';

export type HomePageWideBestSellerProductsProps = StandardDivProps<HomePageWideBestSellerProductsClassKey>;

export type HomePageWideBestSellerProductsClassKey = 'root' | 'productCard' | 'content';

const themeSettings = { name: 'HomePageWideBestSellerProducts' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageWideBestSellerProductsClassKey, {}>({
      root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      productCard: {},
      content: {
        display: 'grid',
        gap: `${theme.spacing(2)}px`,
        gridTemplateColumns: 'repeat(4, minmax(288px, 1fr))',
        overflowX: 'auto',
      },
    }),
  themeSettings,
);

const HomePageWideBestSellerProducts = React.forwardRef<HTMLDivElement, HomePageWideBestSellerProductsProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageHeadline>Nejprodávanější produkty</HomePageHeadline>
        <LoadableBoundary errorFallback={<div>Chyba</div>} suspenseFallback={<div>Loading...</div>}>
          <HomePageWideBestSellerProductsList classes={classes} />
        </LoadableBoundary>
      </div>
    );
  },
);

HomePageWideBestSellerProducts.displayName = 'HomePageWideBestSellerProducts';

export default withThemeProps(themeSettings)(HomePageWideBestSellerProducts);

const HomePageWideBestSellerProductsList: React.FC<{
  classes: Record<string, string>;
}> = ({ classes }) => {
  const currency = useRecoilValue(currencyState);
  const bestSellerProducts = useRecoilValue(homePageBestSellerProductsQuery);

  return (
    <div className={classes.content}>
      {(bestSellerProducts || []).map((product, idx) => (
        <ProductCard key={idx.toString()} product={product} currency={currency} className={classes.productCard} />
      ))}
    </div>
  );
};

HomePageWideBestSellerProductsList.displayName = 'HomePageWideBestSellerProductsList';
