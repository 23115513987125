import { atom, selector } from 'recoil';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export interface BestSellerDimension {
  href: string;
  title: string;
}

export interface BestSellerDimensionGroup {
  dimensions: BestSellerDimension[];
  title: string;
}

export const homePageBestSellerDimensionsQuery = selector<BestSellerDimensionGroup[]>({
  key: 'homePageBestSellerDimensionsQuery',
  get: ({ get }) => {
    const initial = get(homePageBestSellerDimensionsInitialState);
    if (initial) return initial;

    return getHomePageBestSellerDimensions(get(apiClientState));
  },
});

export const homePageBestSellerDimensionsInitialState = GlobalStateRegister.register(
  atom<BestSellerDimensionGroup[] | undefined>({
    key: 'homePageBestSellerDimensionsInitial',
    default: undefined,
  }),
);

export const getHomePageBestSellerDimensions = (apiClient: ApiClient): Promise<BestSellerDimensionGroup[]> => {
  return apiClient.get('/v1/homepage/best-seller/dimensions', {});
};
