import React from 'react';
import {
  AfterPageProps,
  createApiClient,
  createInitialPropsRedirectToLogin,
  makeAfterComponent,
  wrapLayout,
} from '../../@sprinx/react-after-razzle';
import commonThingsToLoad from '../../api/commonThingsToLoad';
import { getHomePageActionArticlesList, homePageActionArticlesInitialsState } from '../../api/homePage/actions';
import { getHomePageArticlesList, homePageArticlesInitialsState } from '../../api/homePage/articles';
import {
  getHomePageBestSellerDimensions,
  homePageBestSellerDimensionsInitialState,
} from '../../api/homePage/bestSellerDimensions';
import { getBestSellerProducts, homePageBestSellerProductsInitialsState } from '../../api/homePage/bestSellerProducts';
import { getHomepageMainBanner, homepageMainBannerInitialState } from '../../api/homePage/mainBanner';
import { getSeasonHintArticleList, seasonHintArticleListInitialState } from '../../api/homePage/seasonTips';
import ErrorInternal from '../../components/ErrorInternal';
import HomePageLayout from './HomePageLayout';

export type HomePageProps = AfterPageProps;

const HomePage: React.FC<HomePageProps> = ({ error }) => {
  if (error) return <ErrorInternal error={error} />;

  return <HomePageLayout />;
};

HomePage.displayName = 'HomePage';

export default makeAfterComponent(wrapLayout(HomePage), async ({ match, initialAppProps }) => {
  try {
    if (initialAppProps) {
      const appProps = initialAppProps;
      const apiClient = createApiClient({ accessToken: appProps.auth?.accessToken });

      const [
        seasonHints,
        actionArticles,
        articles,
        mainBanner,
        bestSellerProducts,
        bestSellerDimensions,
        ...commonThings
      ] = await Promise.all([
        getSeasonHintArticleList(apiClient, appProps.locale),
        getHomePageActionArticlesList(apiClient, appProps.locale),
        getHomePageArticlesList(apiClient, appProps.locale),
        getHomepageMainBanner(apiClient),
        getBestSellerProducts(
          apiClient,
          appProps.locale,
          appProps.currency,
          undefined, // decimalPrecision
          undefined, // enabledDynamicRules
          appProps.pricesType,
          appProps.region,
        ),
        getHomePageBestSellerDimensions(apiClient),
        ...commonThingsToLoad.promises({ apiClient }),
      ]);

      appProps.propagateToState({
        ...commonThingsToLoad.toRegister(commonThings),
        [homePageArticlesInitialsState.key]: articles,
        [homePageActionArticlesInitialsState.key]: actionArticles,
        [seasonHintArticleListInitialState.key]: seasonHints,
        [homepageMainBannerInitialState.key]: mainBanner,
        [homePageBestSellerProductsInitialsState.key]: bestSellerProducts,
        [homePageBestSellerDimensionsInitialState.key]: bestSellerDimensions,
      });
    }

    return {};
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('CataloguePage getInitialProps error: ', error);
    }

    if (error.response?.status === 401) {
      return createInitialPropsRedirectToLogin({ match });
    }
    return { error };
  }
});
