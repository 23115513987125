import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import CheckboxField from '@sprinx/react-mui-fields/CheckboxField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import {
  catalogueCallParamsState,
  catalogueFilterState,
  createDefaultCatalogueCallParams,
} from '../../../api/catalogue/catalogue';
import {
  CatalogueFilterFiledArrayValue,
  useCatalogueFilterFieldStateAxles,
  useCatalogueFilterFieldStateDimensions,
  useCatalogueFilterFieldStateManufacturers,
  useCatalogueFilterFieldStateUsages,
  useCatalogueFilterFieldStateUtilizations,
} from '../../../api/catalogue/catalogueFilter';
import { seasonHintArticleListQuery } from '../../../api/homePage/seasonTips';
import { TireSeasonType } from '../../../api/tireSeasonTypes';
import { VehicleType } from '../../../api/vehicleTypes';
import LoadableBoundary from '../../../components/LoadableBoundary';
import StorexSelectField from '../../../components/StorexSelectField';
import { routeUrl } from '../../../routesBuilder';
import {
  AdviceIcon,
  AgroIcon,
  BusIcon,
  CarIcon,
  ControlWatchIcon,
  MotoIcon,
  MotoSportIcon,
  PhoneIcon,
  Repair2Icon,
  RepairIcon,
  ReservationIcon,
  Tip2Icon,
  Tip3Icon,
  TipIcon,
  WinterSeasonIcon,
} from './HomePageIcons';

export type HomePageNarrowFilterProps = StandardDivProps<HomePageNarrowFilterClassKey>;

export type HomePageNarrowFilterClassKey =
  | 'root'
  | 'filter'
  | 'filterVehicleTypeChoiceWrapper'
  | 'filterVehicleTypeChoice'
  | 'filterVehicleTypeChoiceSelected'
  | 'filterParameterSelectionWrapper'
  | 'filterParamsContainer'
  | 'listBoard'
  | 'field'
  | 'button'
  | 'seasonLinks'
  | 'seasonLinksTitle'
  | 'manufacture'
  | 'manufacturersContainer'
  | 'manufacturersContainerItem'
  | 'filterButton'
  | 'manufactureExpand'
  | 'checkbox'
  | 'filterContainer'
  | 'skeletonContainer';

const themeSettings = { name: 'HomePageNarrowFilter' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNarrowFilterClassKey, {}>({
      root: { ...theme.typography.body1, display: 'flex', flexDirection: 'column', padding: theme.spacing(1, 0) },
      filter: {
        flexGrow: 1,
        background: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 200,
        maxWidth: 972,
      },
      filterVehicleTypeChoiceWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
      },
      filterVehicleTypeChoice: {
        padding: theme.spacing(2, 0),
        textAlign: 'center',
        flexBasis: '25%',
        '& > span': { color: theme.palette.common.white, cursor: 'pointer' },

        [theme.breakpoints.down('xs')]: {
          flexBasis: '50%',
        },
      },
      filterVehicleTypeChoiceSelected: {
        fontWeight: theme.typography.fontWeightBold,
      },
      filterParameterSelectionWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        color: theme.palette.common.white,
        padding: theme.spacing(1, 2),
      },
      filterParamsContainer: {
        marginTop: 'auto',
      },
      listBoard: {
        width: '100%',
        padding: theme.spacing(0, 1),
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
      },
      field: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        width: '50%',
        maxWidth: 165,
        '& > div': { minWidth: '100%' },
      },
      button: {
        color: theme.palette.common.white,
        background: theme.palette.primary.dark,
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2, 0),
      },
      seasonLinks: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
        transition: 'all .2s ease',
        '& > svg': {
          width: theme.spacing(3),
          height: theme.spacing(3),
        },
        fill: theme.palette.primary.main,
      },
      seasonLinksTitle: {
        color: theme.palette.text.primary,
        flexGrow: 1,
        fontSize: theme.typography.pxToRem(14),
      },
      manufacture: {},
      manufacturersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      manufacturersContainerItem: {
        flexBasis: '33.33%',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
          flexBasis: '50%',
        },
      },
      filterButton: {
        color: theme.palette.common.white,
        margin: theme.spacing(2),
        fontSize: 16,
      },
      manufactureExpand: {
        width: '100%',
        boxShadow: theme.shadows[3],
        background: theme.palette.common.white,
        padding: theme.spacing(0, 2),
        display: 'flex',
        flexDirection: 'column',
      },
      checkbox: {
        margin: 0,
        '& label > span:first-of-type': {
          padding: theme.spacing(0.5),
        },
      },
      filterContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      },
      skeletonContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))',
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: 'repeat(2, minmax(150px, 1fr))',
        },
      },
    }),
  themeSettings,
);

const HomePageNarrowFilter = React.forwardRef<HTMLDivElement, HomePageNarrowFilterProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const [expanded, setExpanded] = React.useState(false);

    const catalogueFilter = useRecoilValue(catalogueFilterState);
    const vehicleType = catalogueFilter.vehicleType;

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.filter}>
          <div className={classes.filterVehicleTypeChoiceWrapper}>
            <VehicleTypeChoice
              className={classes.filterVehicleTypeChoice}
              vehicleType='passenger'
              classes={{ selected: classes.filterVehicleTypeChoiceSelected }}
            >
              Osobní a SUV
            </VehicleTypeChoice>
            <VehicleTypeChoice
              className={classes.filterVehicleTypeChoice}
              vehicleType='van'
              classes={{ selected: classes.filterVehicleTypeChoiceSelected }}
            >
              Dodávky
            </VehicleTypeChoice>
            <VehicleTypeChoice
              className={classes.filterVehicleTypeChoice}
              vehicleType='truck'
              classes={{ selected: classes.filterVehicleTypeChoiceSelected }}
            >
              Nákladní auta
            </VehicleTypeChoice>
            <VehicleTypeChoice
              className={classes.filterVehicleTypeChoice}
              vehicleType='moto'
              classes={{ selected: classes.filterVehicleTypeChoiceSelected }}
            >
              Motocykly
            </VehicleTypeChoice>
          </div>
          <div className={classes.filterParamsContainer}>
            <div className={classes.filterParameterSelectionWrapper}>
              <FilterByVehicleTypeSelector classes={classes} vehicleType={vehicleType} />
              <SubmitButton
                disabled={!vehicleType}
                to={
                  vehicleType
                    ? ((dfcp) =>
                        routeUrl('catalogue', {
                          locale,
                          otherParamsToQs: true,
                          params: { ...dfcp, filter: catalogueFilter },
                        }))(createDefaultCatalogueCallParams())
                    : ''
                }
                className={classes.button}
              >
                Zobrazit
              </SubmitButton>
            </div>
            <Link
              component='button'
              onClick={() => setExpanded((ps) => !ps)}
              color='inherit'
              className={classes.filterButton}
            >
              {expanded ? 'Schovat filtr' : 'Filtrovat podle značek'}
            </Link>
          </div>
        </div>
        <Collapse in={expanded}>
          {expanded && <FilterManufacturerSelectionContainer className={classes.manufactureExpand} classes={classes} />}
        </Collapse>
        <div className={classes.listBoard}>
          <HintsLodableComponent>
            <HintsForSeasonLinks classes={classes} />
          </HintsLodableComponent>
        </div>
      </div>
    );
  },
);

HomePageNarrowFilter.displayName = 'HomePageNarrowFilter';

export default withThemeProps(themeSettings)(HomePageNarrowFilter);

const FilterByVehicleTypeSelector: React.FC<{
  classes: Record<'field' | 'filterContainer', string>;
  vehicleType: VehicleType | undefined | null;
}> = ({ classes, vehicleType }) => {
  if (vehicleType === 'moto') return <HomePageWideFilterMoto classes={classes} />;
  if (vehicleType === 'passenger') return <HomePageWideFilterPassenger classes={classes} />;
  if (vehicleType === 'truck') return <HomePageWideFilterTir classes={classes} />;
  if (vehicleType === 'van') return <HomePageWideFilterVan classes={classes} />;

  return <Alert severity='info'>Vyberte typ vozidla.</Alert>;
};

FilterByVehicleTypeSelector.displayName = 'FilterByVehicleTypeSelector';

const HomePageWideFilterMoto: React.FC<{ classes: Record<'field' | 'filterContainer', string> }> = ({ classes }) => {
  return (
    <div className={classes.filterContainer}>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Šířka' stateField='width' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Profil' stateField='profile' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Průměr' stateField='diameter' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireUtilizationSelectBox classes={classes} />
      </LoadableFilterComponent>
    </div>
  );
};
HomePageWideFilterMoto.displayName = 'HomePageWideFilterMoto';

const HomePageWideFilterPassenger: React.FC<{ classes: Record<'field' | 'filterContainer', string> }> = ({
  classes,
}) => {
  return (
    <div className={classes.filterContainer}>
      <LoadableFilterComponent>
        <TireSeasonSelectBox classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Šířka' stateField='width' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Profil' stateField='profile' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Průměr' stateField='diameter' classes={classes} />
      </LoadableFilterComponent>
    </div>
  );
};

HomePageWideFilterPassenger.displayName = 'HomePageWideFilterPassenger';

const HomePageWideFilterTir: React.FC<{ classes: Record<'field' | 'filterContainer', string> }> = ({ classes }) => {
  return (
    <div className={classes.filterContainer}>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Šířka' stateField='width' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Profil' stateField='profile' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Průměr' stateField='diameter' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireAxleSelectBox classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireUsageSelectBox classes={classes} />
      </LoadableFilterComponent>
    </div>
  );
};

HomePageWideFilterTir.displayName = 'HomePageWideFilterTir';

const HomePageWideFilterVan: React.FC<{ classes: Record<'field' | 'filterContainer', string> }> = ({ classes }) => {
  return (
    <div className={classes.filterContainer}>
      <LoadableFilterComponent>
        <TireSeasonSelectBox classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Šířka' stateField='width' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Profil' stateField='profile' classes={classes} />
      </LoadableFilterComponent>
      <LoadableFilterComponent>
        <TireDimensionSelectBox label='Průměr' stateField='diameter' classes={classes} />
      </LoadableFilterComponent>
    </div>
  );
};

HomePageWideFilterVan.displayName = 'HomePageWideFilterVan';

export const VehicleTypeChoice: React.FC<{
  children: React.ReactNode;
  classes: Record<'selected', string>;
  className?: string;
  vehicleType: VehicleType;
}> = ({ children, classes, className, vehicleType }) => {
  const [catalogueCallParams, setCatalogueCallParams] = useRecoilState(catalogueCallParamsState);

  const handleVehicleTypeChoiceClick = React.useCallback(() => {
    setCatalogueCallParams(
      createDefaultCatalogueCallParams({
        vehicleType,
      }),
    );
  }, [setCatalogueCallParams, vehicleType]);

  return (
    <div className={clsx(className, { [classes.selected]: catalogueCallParams.filter.vehicleType === vehicleType })}>
      <Link component='span' onClick={handleVehicleTypeChoiceClick}>
        {children}
      </Link>
    </div>
  );
};

export const SubmitButton = withStyles({})((props: Omit<ButtonProps<RouterLink>, 'component'>) => {
  return <Button component={RouterLink} {...props} variant='contained' />;
});

const TireSeasonSelectBox: React.FC<{ classes: Record<'field', string> }> = ({ classes }) => {
  const [filter, setFilter] = useRecoilState(catalogueFilterState);

  const handleChange = React.useCallback(
    (nextV: undefined | string) => {
      const nextSeason: TireSeasonType = nextV === 'winter' ? 'winter' : 'summer';
      setFilter((prev) => ({
        ...prev,
        tireSeasons: [nextSeason],
      }));
    },
    [setFilter],
  );

  return (
    <StorexSelectField
      onChange={handleChange}
      value={Array.isArray(filter.tireSeasons) ? filter.tireSeasons[0] : undefined}
      label='Období'
      className={classes.field}
    >
      <SelectFieldItem value='summer'>Letní</SelectFieldItem>
      <SelectFieldItem value='winter'>Zimní</SelectFieldItem>
      <SelectFieldItem value='all-season'>Celoroční</SelectFieldItem>
    </StorexSelectField>
  );
};

TireSeasonSelectBox.displayName = 'TireSeasonSelectBox';

const useCatalogueFilterArrayToSingle = (
  useFD: () => CatalogueFilterFiledArrayValue<string> & {
    handleReset: () => void;
  },
): [
  data: { label: string; value: string }[],
  value: string | undefined,
  handleChange: (nextValue: string | undefined) => void,
] => {
  const { data, isSelected, handleChange: pHandleChange, handleReset } = useFD();

  const handleChange = React.useCallback(
    (nextValue: string | undefined) => {
      // clear all other selected
      handleReset();

      if (nextValue) {
        pHandleChange(nextValue)(true);
      }
    },
    [handleReset, pHandleChange],
  );

  const value: string | undefined = data.reduce((r, i) => {
    if (r) return r;
    if (isSelected(i.value)) return i.value;
    return r;
  }, undefined as string | undefined);

  return [data, value, handleChange];
};

const TireAxleSelectBox: React.FC<{ classes: Record<'field', string> }> = ({ classes }) => {
  const [data, value, handleChange] = useCatalogueFilterArrayToSingle(useCatalogueFilterFieldStateAxles);

  return (
    <StorexSelectField onChange={handleChange} value={value} label={'Náprava'} className={classes.field}>
      {data.map((d, i) => (
        <SelectFieldItem key={i.toString()} value={d.value}>
          {d.label}
        </SelectFieldItem>
      ))}
    </StorexSelectField>
  );
};

TireAxleSelectBox.displayName = 'TireAxleSelectBox';

const TireUsageSelectBox: React.FC<{ classes: Record<'field', string> }> = ({ classes }) => {
  const [data, value, handleChange] = useCatalogueFilterArrayToSingle(useCatalogueFilterFieldStateUsages);

  return (
    <StorexSelectField onChange={handleChange} value={value} label={'Provoz'} className={classes.field}>
      {data.map((d, i) => (
        <SelectFieldItem key={i.toString()} value={d.value}>
          {d.label}
        </SelectFieldItem>
      ))}
    </StorexSelectField>
  );
};

TireUsageSelectBox.displayName = 'TireUsageSelectBox';

const TireUtilizationSelectBox: React.FC<{ classes: Record<'field', string> }> = ({ classes }) => {
  const [data, value, handleChange] = useCatalogueFilterArrayToSingle(useCatalogueFilterFieldStateUtilizations);

  return (
    <StorexSelectField onChange={handleChange} value={value} label={'Využití'} className={classes.field}>
      {data.map((d, i) => (
        <SelectFieldItem key={i.toString()} value={d.value}>
          {d.label}
        </SelectFieldItem>
      ))}
    </StorexSelectField>
  );
};
TireUtilizationSelectBox.displayName = 'TireUtilizationSelectBox';

const TireDimensionSelectBox: React.FC<{
  classes: Record<'field', string>;
  label: React.ReactNode;
  stateField: 'diameter' | 'profile' | 'width';
}> = ({ classes, label, stateField }) => {
  const { data, handleChange, value } = useCatalogueFilterFieldStateDimensions(stateField);

  return (
    <StorexSelectField onChange={handleChange} value={value} label={label} className={classes.field}>
      {data.map((d, i) => (
        <SelectFieldItem key={i.toString()} value={d.value}>
          {d.label}
        </SelectFieldItem>
      ))}
    </StorexSelectField>
  );
};

TireDimensionSelectBox.displayName = 'TireDimensionSelectBox';

const LoadableFilterComponent: React.FC = ({ children }) => {
  return (
    <LoadableBoundary
      errorFallback={
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Alert severity='warning' style={{ width: 160 }}>
            Chyba...
          </Alert>
        </Box>
      }
      suspenseFallback={<FilterSkeleton />}
    >
      {children}
    </LoadableBoundary>
  );
};

LoadableFilterComponent.displayName = 'LodableFilterComponent';

const FilterSkeleton: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <Skeleton variant='rect' width={60} height={22} />
      <Skeleton variant='rect' width={120} height={40} />
    </Box>
  );
};

FilterSkeleton.displayName = 'FilterSkeleton';

export const HintsLodableComponent: React.FC = ({ children }) => {
  return (
    <LoadableBoundary
      errorFallback={<Alert severity='warning'>Chyba...</Alert>}
      suspenseFallback={<SeasonLinksSkeleton />}
    >
      {children}
    </LoadableBoundary>
  );
};

HintsLodableComponent.displayName = 'HintsLodableComponent';

const SeasonLinksSkeleton: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Skeleton width={'100%'} height={57} />
      <Skeleton width={'100%'} height={57} />
      <Skeleton width={'100%'} height={57} />
      <Skeleton width={'100%'} height={57} />
    </Box>
  );
};

SeasonLinksSkeleton.displayName = 'SeasonLinksSkeleton';

export const HintsForSeasonLinks: React.FC<{ classes: Record<'seasonLinks' | 'seasonLinksTitle', string> }> = ({
  classes,
}) => {
  const articles = useRecoilValue(seasonHintArticleListQuery);
  const icons = [
    AgroIcon,
    BusIcon,
    CarIcon,
    ControlWatchIcon,
    MotoIcon,
    MotoSportIcon,
    RepairIcon,
    Repair2Icon,
    AdviceIcon,
    ReservationIcon,
    PhoneIcon,
    TipIcon,
    Tip2Icon,
    Tip3Icon,
    WinterSeasonIcon,
  ];

  return (
    <>
      {articles.map((tip, idx) => {
        const Icon = icons[tip.iconIndex - 1] || 'span';
        return (
          <Link key={idx.toString()} component={RouterLink} to={tip.href} className={classes.seasonLinks}>
            <span className={classes.seasonLinksTitle}>{tip.title}</span>
            <Icon />
          </Link>
        );
      })}
    </>
  );
};

HintsForSeasonLinks.displayName = 'HintsForSeasonLinks';

export const FilterManufacturerSelectionContainer: React.FC<{
  classes: Record<string, string>;
  className: string;
}> = ({ classes, className }) => {
  return (
    <div className={className}>
      <LoadableBoundary
        errorFallback={<Alert severity='warning'>Chyba...</Alert>}
        suspenseFallback={
          <Box display='flex' alignItems='flex-end' className={classes.skeletonContainer}>
            {new Array(20).fill(' ').map((i, index) => (
              <Skeleton key={index.toString()} className={classes.manufacturersContainerItem} height={35} width='90%' />
            ))}
          </Box>
        }
      >
        <FilterManufacturerSelectionManufacturers classes={classes} />
      </LoadableBoundary>
    </div>
  );
};

FilterManufacturerSelectionContainer.displayName = 'FilterManufacturerSelectionContainer';

const FilterManufacturerSelectionManufacturers: React.FC<{ classes: Record<string, string> }> = ({ classes }) => {
  const { data, handleChange, handleReset, isSelected } = useCatalogueFilterFieldStateManufacturers();

  return (
    <>
      <Button color='primary' style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleReset}>
        Vymazat filtr
      </Button>
      <div className={classes.manufacturersContainer}>
        {data.map((m, idx) => (
          <div key={idx.toString()} className={classes.manufacturersContainerItem}>
            <CheckboxField
              label={m.label}
              value={isSelected(m.value)}
              onChange={handleChange(m.value)}
              className={classes.checkbox}
            />
          </div>
        ))}
      </div>
    </>
  );
};

FilterManufacturerSelectionManufacturers.displayName = 'FilterManufacturerSelectionManufacturers';
