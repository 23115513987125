import { PromiseType } from 'utility-types';
import { ApiClient } from '../@sprinx/react-after-razzle';
import {
  getProductParameterGroupTypes,
  productParameterGroupTypesInitialsState,
} from './products/productParameterGroupTypes';
import { getProductParameterTypes, productParameterTypesInitialsState } from './products/productParameterTypes';

export interface CommonThingsToLoadProps {
  apiClient: ApiClient;
}

const commonThingsToLoad = transform({
  [productParameterGroupTypesInitialsState.key]: ({ apiClient }) => getProductParameterGroupTypes(apiClient),
  [productParameterTypesInitialsState.key]: ({ apiClient }) => getProductParameterTypes(apiClient),
});

export default commonThingsToLoad;

function transform(cfg: CommonThingsToLoadCfg): CommonThingsToLoad {
  return {
    keys: Object.keys(cfg),
    promises: (props: CommonThingsToLoadProps): Promise<any>[] => Object.values(cfg).map((fn) => fn(props)),
    toRegister: (things: any[]): Record<string, any> =>
      Object.keys(cfg).reduce((acc, key, idx) => ({ ...acc, [key]: things[idx] }), {}),

    // getters
    productParameterGroupTypes: (things) => things[0],
    productParameterTypes: (things) => things[1],
  };
}

type CommonThingsToLoadCfg = Record<string, (props: CommonThingsToLoadProps) => Promise<any>>;

interface CommonThingsToLoad {
  keys: string[];
  productParameterGroupTypes: (things: any[]) => PromiseType<ReturnType<typeof getProductParameterGroupTypes>>;
  productParameterTypes: (things: any[]) => PromiseType<ReturnType<typeof getProductParameterTypes>>;
  promises: (props: CommonThingsToLoadProps) => Promise<any>[];
  toRegister: (things: any[]) => Record<string, any>;
}
