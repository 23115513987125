import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { localeState } from '../../../api/appState';
import { homePageArticlesQuery } from '../../../api/homePage/articles';
import { HomePageVideoArticle, homepageVideoArticleQuerry } from '../../../api/homePage/video';
import ArticleCard from '../../../components/ArticleCard';
import ArticleCardSkeleton from '../../../components/ArticleCard/ArticleCardSkeleton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { routeUrl } from '../../../routesBuilder';
import HomePageHeadline from './HomePageHeadline';

export type HomePageWideArticlesProps = StandardDivProps<HomePageWideArticlesClassKey>;

export type HomePageWideArticlesClassKey =
  | 'root'
  | 'container'
  | 'card'
  | 'cardDescription'
  | 'videoContainer'
  | 'articleContainer'
  | 'downSizeArticle'
  | 'link';

const themeSettings = { name: 'HomePageWideArticles' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageWideArticlesClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
      articleContainer: {},
      container: {
        display: 'grid',
        gap: `${theme.spacing(2)}px`,
        gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'flex-start',
          gridTemplateColumns: 'repeat(4, minmax(260px, 1fr))',
          overflowX: 'auto',
        },
      },
      card: {
        maxWidth: 320,
        minWidth: 260,
        height: 340,
        width: 'auto',
        overflow: 'hidden',
      },
      cardDescription: { height: theme.spacing(10) },
      videoContainer: { display: 'flex' },
      downSizeArticle: { width: '50%' },
      link: {
        alignSelf: 'center',
        marginTop: theme.spacing(3),
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      },
    }),
  themeSettings,
);

const HomePageWideArticles = React.forwardRef<HTMLDivElement, HomePageWideArticlesProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageHeadline id='articles'>Příspěvky</HomePageHeadline>
        <ErrorBoundary fallback={<Alert severity='error'>Došlo k chybě</Alert>}>
          <React.Suspense fallback={<HomePageArticlesSkeleton />}>
            <HomePageArticles />
          </React.Suspense>
        </ErrorBoundary>
        <Link to={routeUrl('posts', { locale, params: {} })} className={classes.link}>
          Zobrazit více
        </Link>
      </div>
    );
  },
);

HomePageWideArticles.displayName = 'HomePageWideArticles';

export default withThemeProps(themeSettings)(HomePageWideArticles);

const HomePageArticles: React.FC = () => {
  const classes = useStyles();
  const articles = useRecoilValue(homePageArticlesQuery);
  const videoLoadable = useRecoilValueLoadable(homepageVideoArticleQuerry);

  if (articles.length === 0) return <Alert severity='warning'>Zadne polozky k zobrazeni</Alert>;

  const visibleVideo = videoLoadable.state === 'hasValue';
  const visibleArticles = visibleVideo ? articles.slice(0, 2) : articles;

  return (
    <div className={clsx(classes.articleContainer, { [classes.videoContainer]: Boolean(visibleVideo) })}>
      <div className={clsx(classes.container, { [classes.downSizeArticle]: Boolean(visibleVideo) })}>
        {(visibleArticles || []).map((i) => (
          <ArticleCard
            article={i}
            key={i.id}
            routeType='postsDetail'
            className={classes.card}
            classes={{ description: classes.cardDescription }}
          />
        ))}
      </div>
      {videoLoadable.state === 'hasValue' && videoLoadable.contents && (
        <HomePageArticleVideoComponent video={videoLoadable.contents} />
      )}
    </div>
  );
};

const HomePageArticlesSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
    </div>
  );
};

const HomePageArticleVideoComponent = withStyles(
  (theme: Theme) => ({
    root: {
      width: '50%',
      marginLeft: theme.spacing(2),
      // background: theme.palette.common.black,
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'HomePageArticleVideoComponent' },
)(
  ({
    classes,
    className,
    video,
  }: {
    classes: Record<'root', string>;
    className?: string;
    video: HomePageVideoArticle;
  }) => {
    const [ready, setReady] = React.useState<boolean>(false);

    React.useEffect(() => {
      setReady(true);
    }, []);

    return <div className={clsx(className, classes.root)}>{ready ? <HomePageArticleVideo video={video} /> : null}</div>;
  },
);

const HomePageArticleVideo = withStyles(
  (theme) => ({
    video: {
      width: '100%',
      height: 0,
      paddingBottom: '56%',
      position: 'relative',
      backgroundColor: theme.palette.background.video,
    },
    videoPlayer: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.black,
      position: 'absolute',
    },
  }),
  { name: 'HomePageArticleVideo' },
)(
  ({
    classes,
    className,
    video,
  }: {
    classes: Record<'video' | 'videoPlayer', string>;
    className?: string;
    video: HomePageVideoArticle;
  }) => {
    return (
      <div className={clsx(classes.video, className)}>
        <YouTube
          className={classes.videoPlayer}
          videoId={((s: string): string => {
            if (s.startsWith('http') && s.includes('youtube')) return new URL(s).searchParams.get('v') as string;
            if (s.startsWith('http')) return (s.match(/([^/]+)$/) || ['', ''])[1];
            // only id of video
            return s;
          })(video.stream)}
          opts={{
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          }}
        />
      </div>
    );
  },
);
