/**
 * Season hints articles
 */

import { atom, selector } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { SupportedLocale } from '../../supported';
import { apiClientState, localeState } from '../appState';
import { HomePageTip } from './HomePageTip';

export const seasonHintArticleListQuery = selector<HomePageTip[]>({
  key: 'seasonHintArticleListQuery',
  get: ({ get }) => {
    const initials = get(seasonHintArticleListInitialState);
    if (initials) return initials;

    return getSeasonHintArticleList(get(apiClientState), get(localeState));
  },
});

export const seasonHintArticleListInitialState = GlobalStateRegister.register(
  atom<HomePageTip[] | undefined>({
    key: 'seasonHintArticleListInitial',
    default: undefined,
  }),
);

export const getSeasonHintArticleList = (apiClient: ApiClient, locale: SupportedLocale): Promise<HomePageTip[]> => {
  invariant(apiClient, "getSeasonHintArticleList(...) requires 'apiClient' to be defined.");
  invariant(locale, "getSeasonHintArticleList(...) requires 'locale' to be defined.");
  return apiClient.get<HomePageTip[], {}>('/v1/homepage/tips', { locale });
};
