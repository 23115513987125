import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import { homePageActionArticlesQuery } from '../../../api/homePage/actions';
import ArticleCard from '../../../components/ArticleCard';
import ArticleCardSkeleton from '../../../components/ArticleCard/ArticleCardSkeleton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { routeUrl } from '../../../routesBuilder';
import HomePageHeadline from './HomePageHeadline';

export type HomePageWideActionArticlesProps = StandardDivProps<HomePageWideActionArticlesClassKey>;

export type HomePageWideActionArticlesClassKey = 'root' | 'container' | 'card' | 'link';

const themeSettings = { name: 'HomePageWideActionArticles' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageWideActionArticlesClassKey, {}>({
      root: { display: 'flex', flexDirection: 'column' },
      container: {
        display: 'grid',
        gap: `${theme.spacing(2)}px`,
        gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'flex-start',
          gridTemplateColumns: 'repeat(4, minmax(260px, 1fr))',
          overflowX: 'auto',
        },
      },
      card: {
        maxWidth: 320,
        minWidth: 260,
        width: 'auto',
      },
      link: {
        alignSelf: 'center',
        marginTop: theme.spacing(3),
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      },
    }),
  themeSettings,
);

const HomePageWideActionArticles = React.forwardRef<HTMLDivElement, HomePageWideActionArticlesProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageHeadline>Akce</HomePageHeadline>
        <ErrorBoundary fallback={<Alert severity='error'>Došlo k chybě</Alert>}>
          <React.Suspense fallback={<HomePageArticlesSkeleton />}>
            <HomePageArticles />
          </React.Suspense>
        </ErrorBoundary>
        <Link to={routeUrl('actions', { locale, params: {} })} className={classes.link}>
          Zobrazit více
        </Link>
      </div>
    );
  },
);

HomePageWideActionArticles.displayName = 'HomePageWideActionArticles';

export default withThemeProps(themeSettings)(HomePageWideActionArticles);

const HomePageArticles: React.FC = () => {
  const classes = useStyles();
  const articles = useRecoilValue(homePageActionArticlesQuery);
  if (articles.length === 0) return <Alert severity='warning'>Zadne polozky k zobrazeni</Alert>;

  return (
    <div className={classes.container}>
      {(articles || []).map((i) => (
        <ArticleCard article={i} key={i.id} className={classes.card} routeType='actionsDetail' />
      ))}
    </div>
  );
};

const HomePageArticlesSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
      <ArticleCardSkeleton className={classes.card} />
    </div>
  );
};
