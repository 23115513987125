import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface HomePageHeadlineProps extends StandardDivProps<HomePageHeadlineClassKey> {
  children: React.ReactNode;
}

export type HomePageHeadlineClassKey = 'root';

const themeSettings = { name: 'HomePageHeadline' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageHeadlineClassKey, {}>({
      root: {
        ...theme.typography.body1,
        fontSize: theme.typography.pxToRem(30),
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: theme.typography.pxToRem(24),
        },
      },
    }),
  themeSettings,
);

const HomePageHeadline = React.forwardRef<HTMLDivElement, HomePageHeadlineProps>(
  ({ children, className, classes: pClasses, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)} {...rest}>
        {children}
      </div>
    );
  },
);

HomePageHeadline.displayName = 'HomePageHeadline';

export default withThemeProps(themeSettings)(HomePageHeadline);
