import { atom, selector } from 'recoil';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import { HomePageArticle } from './HomePageArticle';

export const homePageActionArticlesQuery = selector<HomePageArticle[]>({
  key: 'homePageActionArticlesQuery',
  get: ({ get }) => {
    const initials = get(homePageActionArticlesInitialsState);
    if (initials) return initials;

    return getHomePageActionArticlesList(get(apiClientState), get(localeState));
  },
});

export const homePageActionArticlesInitialsState = GlobalStateRegister.register(
  atom<HomePageArticle[] | undefined>({
    key: 'homePageActionArticlesInitials',
    default: undefined,
  }),
);

export const getHomePageActionArticlesList = (apiClient: ApiClient, locale: string): Promise<HomePageArticle[]> => {
  return apiClient.get<HomePageArticle[], {}>('/v1/homepage/actions', { locale });
};
