import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import HomePageMainBanner from './HomePageMainBanner';
import HomePageNavigationBoard from './HomePageNavigationBoard';
import HomePageNewsletter from './HomePageNewsletter';
import HomePageWideActionArticles from './HomePageWideActionArticles';
import HomePageWideArticles from './HomePageWideArticles';
import HomePageWideBestSellerDimensions from './HomePageWideBestSellerDimensions';
import HomePageWideBestSellerProducts from './HomePageWideBestSellerProducts';
import HomePageWideFilter from './HomePageWideFilter';

export type HomePageWideContentProps = StandardDivProps<HomePageWideContentClassKey>;

export type HomePageWideContentClassKey = 'root' | 'layout';

const themeSettings = { name: 'HomePageWideContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageWideContentClassKey, {}>({
      root: { display: 'flex', flexDirection: 'column', alignItems: 'stretch' },
      layout: {
        padding: theme.spacing(4, 0),
      },
    }),
  themeSettings,
);

const HomePageWideContent = React.forwardRef<HTMLDivElement, HomePageWideContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageNavigationBoard />
        <HomePageMainBanner />
        <HomePageWideFilter />
        <HomePageWideBestSellerProducts className={classes.layout} />
        <HomePageWideBestSellerDimensions className={classes.layout} />
        <HomePageWideArticles className={classes.layout} />
        <HomePageWideActionArticles className={classes.layout} />
        <HomePageNewsletter className={classes.layout} />
      </div>
    );
  },
);

HomePageWideContent.displayName = 'HomePageWideContent';

export default withThemeProps(themeSettings)(HomePageWideContent);
