import { SelectProps } from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import clsx from 'clsx';
import React from 'react';

export interface StorexSelectFieldProps extends Omit<StandardDivProps<StorexSelectFieldClassKey>, 'onChange'> {
  error?: string;
  label?: React.ReactNode;
  onChange: (value: undefined | string) => void;
  placeholder?: string;
  SelectProps?: Partial<SelectProps>;
  value?: string;
}

export type StorexSelectFieldClassKey =
  | 'root'
  | 'placeholder'
  | 'selectField'
  | 'icon'
  | 'selectMenu'
  | 'selectedItem'
  | 'label';

const themeSettings = { name: 'StorexSelectField' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<StorexSelectFieldClassKey, {}>({
      root: { display: 'flex', alignItems: 'center', flexGrow: 1, width: '100%' },
      selectField: {
        minWidth: 130,
        background: theme.palette.common.white,
        position: 'relative',

        [theme.breakpoints.down('xs')]: { minWidth: '50%', maxWidth: '100%' },
      },
      icon: { color: theme.palette.primary.main },
      selectMenu: { fontWeight: 600, padding: '10.5px 14px' },
      selectedItem: { '& > li': { color: theme.palette.text.primary } },
      label: { flexGrow: 1 },
      placeholder: {
        position: 'absolute',
        width: theme.spacing(8),
        top: '50%',
        bottom: 'unset',
        left: 'calc(50% - 16px)',
        transform: 'translate(-50%,-50%)',
        pointerEvents: 'none',
        margin: 0,
        opacity: 1,
        border: 'none',
        background: 'transparent',
      },
    }),

  themeSettings,
);

const StorexSelectField = React.forwardRef<HTMLDivElement, StorexSelectFieldProps>(
  ({ className, classes: pClasses, error, value, onChange, children, label, placeholder = 'Vyberte' }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const handleChange = React.useCallback(
      (value: string) => {
        if (onChange) {
          onChange(value);
        }
      },
      [onChange],
    );

    return (
      <div className={clsx(classes.root, className)} ref={ref}>
        {label && <span className={classes.label}>{label}</span>}
        <SelectField
          error={error}
          value={value || ''}
          onChange={(v) => handleChange(v || '')}
          className={classes.selectField}
          SelectProps={{
            IconComponent: ExpandIcon,
            inputProps: {
              classes: {
                nativeInput: clsx({ [classes.placeholder]: !value }),
              },
              placeholder: placeholder,
            },
            classes: {
              icon: classes.icon,
              selectMenu: classes.selectMenu,
            },
            MenuProps: {
              classes: { list: classes.selectedItem },
            },
          }}
          variant='outlined'
        >
          {children}
        </SelectField>
      </div>
    );
  },
);

StorexSelectField.displayName = 'StorexSelectField';

export default withThemeProps(themeSettings)(StorexSelectField);
