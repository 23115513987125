import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

export interface ProductListCarouselProps extends StandardDivProps<ProductListCarouselClassKey> {
  children: React.ReactNode;
  interval?: number;
}

export type ProductListCarouselClassKey = 'root' | 'carousel';

const themeSettings = { name: 'ProductListCarousel' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductListCarouselClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0),
        },
      },
      carousel: {
        position: 'relative',
        width: '100%',
        paddingBottom: theme.spacing(1),
      },
    }),
  themeSettings,
);

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProductListCarousel = React.forwardRef<HTMLDivElement, ProductListCarouselProps>(
  ({ children, className, classes: pClasses, interval = 6000 }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const [index, setIndex] = React.useState<number>(0);

    const handleChange = React.useCallback((i: number) => {
      setIndex(i);
    }, []);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={handleChange}
          animateHeight
          enableMouseEvents
          interval={interval}
          className={classes.carousel}
        >
          {React.Children.map(children, (child: any) => React.cloneElement(child))}
        </AutoPlaySwipeableViews>
        <ProductListCarouselPagination dots={React.Children.count(children)} index={index} onChange={handleChange} />
      </div>
    );
  },
);

ProductListCarousel.displayName = 'ProductListCarousel';

export default withThemeProps(themeSettings)(ProductListCarousel);

const ProductListCarouselPagination = withStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      padding: theme.spacing(2),
      position: 'absolute',
      bottom: '0',
      zIndex: 999,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
  { name: 'ProductListCarouselPagination' },
)(
  ({
    classes,
    className,
    dots,
    index,
    onChange: handleChange,
  }: {
    classes: Record<'root', string>;
    className?: string;
    dots: number;
    index: number;
    onChange: (value: number) => void;
  }) => {
    const handleClick = React.useCallback(
      (idx) => {
        handleChange(idx);
      },
      [handleChange],
    );
    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(<ProductListCarouselPaginationDot key={i} index={i} active={i === index} onClick={handleClick} />);
    }

    return <div className={clsx(classes.root, className)}>{children}</div>;
  },
);

const ProductListCarouselPaginationDot = withStyles(
  (theme: Theme) => ({
    button: {
      height: 18,
      width: 18,
      cursor: 'pointer',
      border: 0,
      background: 'none',
      padding: 0,
      outline: 'none',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        height: 15,
        width: 15,
      },
    },
    dot: {
      backgroundColor: theme.palette.grey.A200,
      height: 15,
      width: 15,
      borderRadius: 6,
      margin: 0,
      [theme.breakpoints.down('md')]: {
        height: 12,
        width: 12,
      },
    },
    active: { backgroundColor: theme.palette.primary.main },
  }),
  { name: 'ProductListCarouselPaginationDot' },
)(
  ({
    className,
    classes,
    active,
    index,
    onClick: handleClick,
  }: {
    active: boolean;
    classes: Record<'button' | 'dot' | 'active', string>;
    className?: string;
    index: number;
    onClick: (value: number) => void;
  }) => {
    const handleToggleClick = React.useCallback(() => {
      handleClick(index);
    }, [handleClick, index]);

    return (
      <button type='button' className={clsx(classes.button, className)} onClick={handleToggleClick}>
        <div className={clsx(classes.dot, { [classes.active]: active })} />
      </button>
    );
  },
);
