import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import { routeUrl } from '../../../routesBuilder';

export type HomePageNavigationBoardProps = StandardDivProps<HomePageNavigationBoardClassKey>;

export type HomePageNavigationBoardClassKey = 'root' | 'link';

const themeSettings = { name: 'HomePageNavigationBoard' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNavigationBoardClassKey, {}>({
      root: { flexGrow: 1, textAlign: 'center', padding: theme.spacing(2) },
      link: {
        margin: theme.spacing(2),
        fontWeight: 500,
        transition: 'all .2s ease',
        '&:hover': {
          color: theme.palette.primary.dark,
          textDecoration: 'none',
        },
      },
    }),
  themeSettings,
);

const HomePageNavigationBoard = React.forwardRef<HTMLDivElement, HomePageNavigationBoardProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Link component={RouterLink} to={routeUrl('tireSelection', { locale, params: {} })} className={classes.link}>
          Výběr pneumatiky
        </Link>
        <Link component={'a'} href={'#articles'} className={classes.link}>
          Články a tipy
        </Link>
        <Link component={RouterLink} to={routeUrl('ourServices', { locale, params: {} })} className={classes.link}>
          Servisní služby
        </Link>
        <Link component={RouterLink} to={routeUrl('branchList', { locale, params: {} })} className={classes.link}>
          Naše pneuservisy
        </Link>
      </div>
    );
  },
);

HomePageNavigationBoard.displayName = 'HomePageNavigationBoard';

export default withThemeProps(themeSettings)(HomePageNavigationBoard);
