import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';

export const newsletterRegistrationRegister = (
  apiClient: ApiClient,
  params: NewsleterRegistration,
): Promise<NewsleterRegistrationResult> => {
  invariant(apiClient, 'newsletterRegistrationRegister(...) requires apiClient to be defined.');
  invariant(params, 'newsletterRegistrationRegister(...) requires params to be defined.');

  return apiClient.post('/v1/newsletter', params);
};

export interface NewsleterRegistration {
  email: string;
}

export type NewsleterRegistrationResult =
  | {
      status: 'success';
    }
  | {
      msg: 'already-registered' | string | Error;
      status: 'error';
    };
