import React from 'react';

export interface ErrorBoundaryProps {
  fallback?: React.ReactElement;
  onReset?: () => void;
  renderFallback?: (props: { error: Error; reset: () => void }) => JSX.Element;
}

export interface ErrorBoundaryState {
  error: Error | undefined;
}

const initialState: ErrorBoundaryState = { error: undefined };

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { error };
  }

  state = initialState;

  componentDidCatch(error: Error, errorInfo: { componentStack: string }): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo);
  }

  reset(): void {
    this.setState(initialState);
  }

  render(): JSX.Element | null {
    const { error } = this.state;

    if (error !== undefined) {
      if (React.isValidElement(this.props.fallback)) {
        return this.props.fallback;
      }

      if (typeof this.props.renderFallback === 'function') {
        return this.props.renderFallback({ error, reset: this.reset.bind(this) });
      }
    }

    return <>{this.props.children}</>;
  }
}
