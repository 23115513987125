import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  BestSellerDimension,
  BestSellerDimensionGroup,
  homePageBestSellerDimensionsQuery,
} from '../../../api/homePage/bestSellerDimensions';
import LoadableBoundary from '../../../components/LoadableBoundary';
import ProductListCarousel from '../../../components/ProductListCarousel';
import HomePageHeadline from './HomePageHeadline';

export type HomePageNarrowBestSellerDimensionsProps = StandardDivProps<HomePageNarrowBestSellerDimensionsClassKey>;

export type HomePageNarrowBestSellerDimensionsClassKey =
  | 'root'
  | 'dimensionCard'
  | 'dimensionItem'
  | 'dimensionItemContainer'
  | 'dimensionCardTitle';

const themeSettings = { name: 'HomePageNarrowBestSellerDimensions' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNarrowBestSellerDimensionsClassKey, {}>({
      root: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
      },
      dimensionCard: {
        minWidth: 260,
        maxWidth: 320,
        padding: theme.spacing(1),
      },
      dimensionItem: {
        flexShrink: 0,
        flexBasis: '50%',
        padding: theme.spacing(1, 0),
        color: theme.palette.text.primary,
      },
      dimensionItemContainer: { display: 'flex', flexWrap: 'wrap' },
      dimensionCardTitle: { marginBottom: theme.spacing(2), color: theme.palette.primary.dark, fontSize: 17 },
    }),
  themeSettings,
);

const HomePageNarrowBestSellerDimensions = React.forwardRef<HTMLDivElement, HomePageNarrowBestSellerDimensionsProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageHeadline>Nejprodávanější rozměry</HomePageHeadline>
        <LoadableBoundary
          errorFallback={<Alert severity='warning'>Došlo k chybě při načítání...</Alert>}
          suspenseFallback={<HomePageWideBestSellerDimensionsCardSkeleton classes={classes} />}
        >
          <HomePageWideBestSellerDimensionsList classes={classes} />
        </LoadableBoundary>
      </div>
    );
  },
);

HomePageNarrowBestSellerDimensions.displayName = 'HomePageNarrowBestSellerDimensions';

export default withThemeProps(themeSettings)(HomePageNarrowBestSellerDimensions);

const HomePageWideBestSellerDimensionsList: React.FC<{
  classes: Record<'dimensionCard' | 'dimensionItem' | 'dimensionItemContainer' | 'dimensionCardTitle', string>;
}> = ({ classes }) => {
  const dimensionGroups = useRecoilValue(homePageBestSellerDimensionsQuery);

  return (
    <ProductListCarousel>
      {(dimensionGroups || []).map((grp, gIdx) => (
        <HomePageWideBestSellerDimensionsListGroup key={gIdx.toString()} group={grp} classes={classes} />
      ))}
    </ProductListCarousel>
  );
};

HomePageWideBestSellerDimensionsList.displayName = 'HomePageWideBestSellerDimensionsList';

const HomePageWideBestSellerDimensionsListGroup: React.FC<{
  classes: Record<'dimensionCard' | 'dimensionCardTitle' | 'dimensionItem' | 'dimensionItemContainer', string>;
  group: BestSellerDimensionGroup;
}> = ({ group, classes }) => {
  return (
    <div className={classes.dimensionCard}>
      <div className={classes.dimensionCardTitle}>{group.title}</div>
      <div className={classes.dimensionItemContainer}>
        {group.dimensions.map((d, dIdx) => (
          <HomePageWideBestSellerDimensionsListGroupItem key={dIdx.toString()} dimension={d} classes={classes} />
        ))}
      </div>
    </div>
  );
};

const HomePageWideBestSellerDimensionsListGroupItem: React.FC<{
  classes: Record<'dimensionItem', string>;
  dimension: BestSellerDimension;
}> = ({ dimension, classes }) => {
  return (
    <Link component={RouterLink} to={dimension.href} className={classes.dimensionItem}>
      {dimension.title}
    </Link>
  );
};

HomePageWideBestSellerDimensionsListGroupItem.displayName = 'HomePageWideBestSellerDimensionsListGroupItem';

const HomePageWideBestSellerDimensionsCardSkeleton: React.FC<{
  classes: Record<'dimensionCard' | 'dimensionCardTitle', string>;
}> = ({ classes }) => {
  return (
    <>
      {new Array(4).fill(' ').map((i, index) => (
        <Box className={classes.dimensionCard} key={index.toString()}>
          <Skeleton className={classes.dimensionCardTitle} height={23} width={150} variant='rect' />
          <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
            {new Array(6).fill(' ').map((i, index) => (
              <Skeleton width={100} height={38} key={index.toString()} />
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};

HomePageWideBestSellerDimensionsCardSkeleton.displayName = 'HomePageWideBestSellerDimensionsCardSkeleton';
