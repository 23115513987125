import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import type { FormikExtendedBag } from '@sprinx/react-forms';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiClientState } from '../../../api/appState';
import { NewsleterRegistration, newsletterRegistrationRegister } from '../../../api/homePage/newsletterRegistration';
import { enqueueSnackbarMessage } from '../../../api/snackbars';
import StorexTextField from '../../../components/StorexTextField';

export type HomePageNewsletterProps = StandardDivProps<HomePageNewsletterClassKey>;

export type HomePageNewsletterClassKey = 'root' | 'headline' | 'textField' | 'container' | 'message';

const themeSettings = { name: 'HomePageNewsletter' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNewsletterClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...theme.typography.body1,
      },
      headline: { fontWeight: theme.typography.fontWeightMedium, fontSize: theme.typography.pxToRem(20) },
      textField: { margin: theme.spacing(2, 0), width: '100%', '& > div': { minWidth: 'unset' } },
      container: {
        maxWidth: 280,
      },
      message: { marginTop: theme.spacing(2) },
    }),
  themeSettings,
);

const HomePageNewsletter = React.forwardRef<HTMLDivElement, HomePageNewsletterProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslate();
    const [resultIsSuccess, setShowSuccessResultMessage] = React.useState<boolean>(false);
    const apiClient = useRecoilValue(apiClientState);
    const showSnackbarMessage = useSetRecoilState(enqueueSnackbarMessage);

    const handleSubmit = React.useCallback(
      async (value: NewsleterRegistration, bag: FormikExtendedBag<NewsleterRegistration>): Promise<boolean | void> => {
        try {
          const res = await newsletterRegistrationRegister(apiClient, value);

          bag.setSubmitting(false);

          if (res.status === 'error') {
            const errorMessage = res.msg === 'already-registered' ? 'already-registered' : 'Při uložení došlo k chybě.';

            throw new Error(errorMessage);
          }

          setShowSuccessResultMessage(true);

          return true;
        } catch (err) {
          if (err.message === 'already-registered') {
            bag.setErrors({ email: 'Email adresa je již registrována' });
          } else {
            showSnackbarMessage({
              message: err.message,
              variant: 'error',
            });

            if (process.env.NODE_ENV !== 'production') {
              console.error('HomePageNewsletter handleSave error', err);
            }
          }
          bag.setSubmitting(false);
        }
      },
      [apiClient, showSnackbarMessage],
    );

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <span className={classes.headline}>Odebírejte naše novinky a akce!</span>
        {resultIsSuccess ? (
          <Alert
            severity='success'
            action={
              <Button color='inherit' size='small' onClick={() => setShowSuccessResultMessage(false)}>
                Ok
              </Button>
            }
            className={classes.message}
          >
            <AlertTitle>E-mail úspěšně odeslán.</AlertTitle>
            Děkujeme za přihlášení k newsletteru!
          </Alert>
        ) : (
          <FormikExtended<NewsleterRegistration>
            translator={t}
            initialValues={{ email: '' }}
            validationFastest
            validateOnChange={false}
            validateOnBlur
            validationSchema={{
              email: { type: 'email', empty: false },
            }}
            onSubmit={handleSubmit}
          >
            {({ values, errors, onBlur: handleBlur, onChange: handleChange, isSubmitting, onSubmit: handleSubmit }) => (
              <form className={classes.container} onSubmit={handleSubmit}>
                <StorexTextField
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  label={'Zadejte váš e-mail'}
                  className={classes.textField}
                  required
                />
                <Button variant='contained' color='primary' size='large' type='submit' disabled={isSubmitting}>
                  Přihlásit se k odběru
                </Button>
              </form>
            )}
          </FormikExtended>
        )}
      </div>
    );
  },
);

HomePageNewsletter.displayName = 'HomePageNewsletter';

export default withThemeProps(themeSettings)(HomePageNewsletter);
