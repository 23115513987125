import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import { homePageBestSellerProductsQuery } from '../../../api/homePage/bestSellerProducts';
import LoadableBoundary from '../../../components/LoadableBoundary';
import ProductCard from '../../../components/ProductCard';
import ProductListCarousel from '../../../components/ProductListCarousel';
import HomePageHeadline from './HomePageHeadline';

export type HomePageNarrowBestSellerProductsProps = StandardDivProps<HomePageNarrowBestSellerProductsClassKey>;

export type HomePageNarrowBestSellerProductsClassKey = 'root' | 'productCard' | 'image';

const themeSettings = { name: 'HomePageNarrowBestSellerProducts' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNarrowBestSellerProductsClassKey, {}>({
      root: { padding: theme.spacing(2, 0) },
      productCard: {
        margin: theme.spacing(0, 'auto'),
      },
      image: {
        [theme.breakpoints.down('xs')]: {
          width: theme.spacing(22),
          height: theme.spacing(16),
        },
      },
    }),
  themeSettings,
);

const HomePageNarrowBestSellerProducts = React.forwardRef<HTMLDivElement, HomePageNarrowBestSellerProductsProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageHeadline>Nejprodávanější produkty</HomePageHeadline>
        <LoadableBoundary errorFallback={<div>Chyba</div>} suspenseFallback={<div>Loading...</div>}>
          <HomePageNarrowBestSellerProductsList classes={classes} />
        </LoadableBoundary>
      </div>
    );
  },
);

HomePageNarrowBestSellerProducts.displayName = 'HomePageNarrowBestSellerProducts';

export default withThemeProps(themeSettings)(HomePageNarrowBestSellerProducts);

const HomePageNarrowBestSellerProductsList: React.FC<{
  classes: Record<string, string>;
}> = ({ classes }) => {
  const currency = useRecoilValue(currencyState);
  const bestSellerProducts = useRecoilValue(homePageBestSellerProductsQuery);

  return (
    <ProductListCarousel interval={6000}>
      {(bestSellerProducts || []).map((product, idx) => (
        <ProductCard key={idx.toString()} product={product} currency={currency} className={classes.productCard} />
      ))}
    </ProductListCarousel>
  );
};

HomePageNarrowBestSellerProductsList.displayName = 'HomePageNarrowBestSellerProductsList';
