import React from 'react';

export const AgroIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <g>
        <path
          d='M19.5,12C20.43,12 21.28,12.28 22,12.76L22,8C22,6.9 21.1,6 20,6L13.71,6L12.65,4.94L14.06,3.53L13.35,2.82L9.82,6.35L10.53,7.06L11.94,5.65L13,6.71L13,9C13,10.1 12.1,11 11,11L10.46,11C11.41,12.06 12,13.46 12,15C12,15.34 11.96,15.67 11.91,16L15.05,16C15.3,13.75 17.19,12 19.5,12Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M19.5,13C17.57,13 16,14.57 16,16.5C16,18.43 17.57,20 19.5,20C21.43,20 23,18.43 23,16.5C23,14.57 21.43,13 19.5,13ZM19.5,18C18.67,18 18,17.33 18,16.5C18,15.67 18.67,15 19.5,15C20.33,15 21,15.67 21,16.5C21,17.33 20.33,18 19.5,18Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M4,9L9,9C9,7.9 8.1,7 7,7L4,7C3.45,7 3,7.45 3,8C3,8.55 3.45,9 4,9Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M9.83,13.82L9.65,13.35L10.58,13C10.12,11.94 9.3,11.09 8.27,10.57L7.87,11.46L7.41,11.25L7.81,10.35C7.26,10.13 6.64,10 6,10C5.47,10 4.96,10.11 4.48,10.26L4.82,11.17L4.35,11.35L4,10.42C2.94,10.88 2.09,11.7 1.57,12.73L2.46,13.13L2.25,13.59L1.35,13.19C1.13,13.74 1,14.36 1,15C1,15.53 1.11,16.04 1.26,16.52L2.17,16.18L2.35,16.65L1.42,17C1.88,18.06 2.7,18.91 3.73,19.43L4.13,18.54L4.59,18.75L4.19,19.65C4.74,19.87 5.36,20 6,20C6.53,20 7.04,19.89 7.52,19.74L7.18,18.83L7.65,18.65L8,19.58C9.06,19.12 9.91,18.3 10.43,17.27L9.54,16.87L9.75,16.41L10.65,16.81C10.87,16.26 11,15.64 11,15C11,14.47 10.89,13.96 10.74,13.48L9.83,13.82ZM7.15,17.77C5.62,18.4 3.86,17.68 3.23,16.15C2.6,14.62 3.32,12.86 4.85,12.23C6.38,11.6 8.14,12.32 8.77,13.85C9.41,15.38 8.68,17.14 7.15,17.77Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
      </g>
    </g>
  </svg>
);

AgroIcon.displayName = 'AgroIcon';

export const BusIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <rect x='0' y='0' width='24' height='24' style={{ fill: 'none', fillRule: 'nonzero' }} />
    <path
      d='M4,16C4,16.88 4.39,17.67 5,18.22L5,20C5,20.55 5.45,21 6,21L7,21C7.55,21 8,20.55 8,20L8,19L16,19L16,20C16,20.55 16.45,21 17,21L18,21C18.55,21 19,20.55 19,20L19,18.22C19.61,17.67 20,16.88 20,16L20,6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6L4,16ZM7.5,17C6.67,17 6,16.33 6,15.5C6,14.67 6.67,14 7.5,14C8.33,14 9,14.67 9,15.5C9,16.33 8.33,17 7.5,17ZM16.5,17C15.67,17 15,16.33 15,15.5C15,14.67 15.67,14 16.5,14C17.33,14 18,14.67 18,15.5C18,16.33 17.33,17 16.5,17ZM18,11L6,11L6,6L18,6L18,11Z'
      style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
    />
  </svg>
);

BusIcon.displayName = 'BusIcon';

export const CarIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <rect x='0' y='0' width='24' height='24' style={{ fill: 'none', fillRule: 'nonzero' }} />
    <path
      d='M18.92,6.01C18.72,5.42 18.16,5 17.5,5L6.5,5C5.84,5 5.29,5.42 5.08,6.01L3,12L3,20C3,20.55 3.45,21 4,21L5,21C5.55,21 6,20.55 6,20L6,19L18,19L18,20C18,20.55 18.45,21 19,21L20,21C20.55,21 21,20.55 21,20L21,12L18.92,6.01ZM6.5,16C5.67,16 5,15.33 5,14.5C5,13.67 5.67,13 6.5,13C7.33,13 8,13.67 8,14.5C8,15.33 7.33,16 6.5,16ZM17.5,16C16.67,16 16,15.33 16,14.5C16,13.67 16.67,13 17.5,13C18.33,13 19,13.67 19,14.5C19,15.33 18.33,16 17.5,16ZM5,11L6.5,6.5L17.5,6.5L19,11L5,11Z'
      style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
    />
  </svg>
);

CarIcon.displayName = 'CarIcon';

export const ControlWatchIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <rect x='0' y='0' width='24' height='24' style={{ fill: 'none', fillRule: 'nonzero' }} />
    <path
      d='M11.99,2C6.47,2 1.99,6.48 1.99,12C1.99,17.52 6.47,22 11.99,22C17.51,22 21.99,17.52 21.99,12C21.99,6.48 17.51,2 11.99,2ZM15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2C14.53,12.2 13.67,11.34 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34ZM9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.48 9.6,11.48C8.3,11.48 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76ZM9.6,15.89L9.6,19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.51,13.56 8.13,12.99 9.6,12.99C10.13,12.99 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89ZM11.99,20C11.72,20 11.46,19.99 11.2,19.96L11.2,15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.52,14.15 19.44,14.91C18.27,17.88 15.38,20 11.99,20Z'
      style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
    />
  </svg>
);

ControlWatchIcon.displayName = 'ControlWatchIcon';

export const MotoIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
      <path
        d='M20,11C19.82,11 19.64,11.03 19.47,11.05L17.41,9L20,9L20,6L16.28,7.86L13.41,5L9,5L9,7L12.59,7L14.59,9L11,9L7,11L5,9L0,9L0,11L4,11C1.79,11 0,12.79 0,15C0,17.21 1.79,19 4,19C6.21,19 8,17.21 8,15L10,17L13,17L16.49,10.9L17.5,11.91C16.59,12.64 16,13.75 16,15C16,17.21 17.79,19 20,19C22.21,19 24,17.21 24,15C24,12.79 22.21,11 20,11ZM4,17C2.9,17 2,16.1 2,15C2,13.9 2.9,13 4,13C5.1,13 6,13.9 6,15C6,16.1 5.1,17 4,17ZM20,17C18.9,17 18,16.1 18,15C18,13.9 18.9,13 20,13C21.1,13 22,13.9 22,15C22,16.1 21.1,17 20,17Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

MotoIcon.displayName = 'MotoIcon';

export const MotoSportIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <g>
        <path
          d='M12,11.39C12,10.74 11.61,10.16 11.02,9.91L5.44,7.55C3.96,9.23 3.12,11.25 2.64,13L10.39,13C11.28,13 12,12.28 12,11.39Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <path
          d='M21.96,11.22C21.55,6.81 17.4,3.73 12.98,4.02C10.47,4.18 8.54,4.96 7.05,6.06L11.79,8.07C13.12,8.64 13.99,9.94 13.99,11.39C13.99,13.38 12.37,15 10.38,15L2.21,15C2,16.31 2,17.2 2,17.2L2,18C2,19.1 2.9,20 4,20L14,20C18.67,20 22.41,15.99 21.96,11.22Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
      </g>
    </g>
  </svg>
);

MotoSportIcon.displayName = 'MotoSportIcon';

export const RepairIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <g>
        <path
          d='M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM17.638,15.625L15.697,17.526C15.497,17.726 15.114,17.763 14.914,17.563L11.001,13.798C9.781,14.228 7.859,13.594 6.909,12.624C5.838,11.53 5.166,8.725 6.322,7.545L9.52,10.92L10.93,9.51L7.666,6.236C8.899,5.113 11.617,5.56 12.727,6.67C13.707,7.65 14.306,9.726 13.876,10.946L17.657,14.736C17.847,14.926 17.828,15.435 17.638,15.625Z'
          style={{ fill: 'rgb(237,28,36)' }}
        />
      </g>
    </g>
  </svg>
);

RepairIcon.displayName = 'RepairIcon';

export const Repair2Icon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <g>
        <path
          d='M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM16.9,15.49L15.5,16.89C15.3,17.09 14.99,17.09 14.79,16.89L11.38,13.48C10.16,13.91 8.74,13.65 7.76,12.67C6.65,11.56 6.46,9.88 7.17,8.57L9.52,10.92L10.93,9.51L8.58,7.17C9.9,6.46 11.57,6.65 12.68,7.76C13.66,8.74 13.92,10.16 13.49,11.38L16.9,14.79C17.09,14.98 17.09,15.3 16.9,15.49Z'
          style={{ fill: 'rgb(237,28,36)' }}
        />
      </g>
    </g>
  </svg>
);

Repair2Icon.displayName = 'Repair2Icon';

export const AdviceIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <g>
        <circle cx='10' cy='8' r='4' style={{ fill: 'rgb(237,28,36)' }} />
        <path
          d='M11.54,14.626C11.54,14.283 11.262,14.005 10.919,14.005L9.677,14.005C9.334,14.005 9.056,14.283 9.056,14.626L9.056,17.729C9.056,18.072 9.334,18.35 9.677,18.35L10.919,18.35C11.262,18.35 11.54,18.072 11.54,17.729L11.54,14.626Z'
          style={{ fill: 'rgb(237,28,36)' }}
        />
        <path
          d='M10.35,14.01C7.62,13.91 2,15.27 2,18L2,20L11.54,20C9.07,17.24 10.31,14.11 10.35,14.01Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
        <g transform='matrix(-1,0,0,1,20.5958,0)'>
          <path
            d='M10.35,14.01C7.62,13.91 2,15.27 2,18L2,20L11.54,20C9.07,17.24 10.31,14.11 10.35,14.01Z'
            style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </g>
    <g transform='matrix(0.243669,0,0,0.243669,13.4788,3.62161)'>
      <path
        d='M17,20C16.71,20 16.44,19.94 16.24,19.85C15.53,19.48 15.03,18.97 14.53,17.47C14.02,15.91 13.06,15.18 12.14,14.47C11.35,13.86 10.53,13.23 9.82,11.94C9.29,10.98 9,9.93 9,9C9,6.2 11.2,4 14,4C16.8,4 19,6.2 19,9L21,9C21,5.07 17.93,2 14,2C10.07,2 7,5.07 7,9C7,10.26 7.38,11.65 8.07,12.9C8.98,14.55 10.05,15.38 10.92,16.05C11.73,16.67 12.31,17.12 12.63,18.1C13.23,19.92 14,20.94 15.36,21.65C15.87,21.88 16.43,22 17,22C19.21,22 21,20.21 21,18L19,18C19,19.1 18.1,20 17,20ZM7.64,2.64L6.22,1.22C4.23,3.21 3,5.96 3,9C3,12.04 4.23,14.79 6.22,16.78L7.63,15.37C6.01,13.74 5,11.49 5,9C5,6.51 6.01,4.26 7.64,2.64ZM11.5,9C11.5,10.38 12.62,11.5 14,11.5C15.38,11.5 16.5,10.38 16.5,9C16.5,7.62 15.38,6.5 14,6.5C12.62,6.5 11.5,7.62 11.5,9Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

AdviceIcon.displayName = 'AdviceIcon';

export const ReservationIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <rect x='0' y='0' width='24' height='24' style={{ fill: 'none', fillRule: 'nonzero' }} />
    <path
      d='M20,3L19,3L19,1L17,1L17,3L7,3L7,1L5,1L5,3L4,3C2.9,3 2,3.9 2,5L2,21C2,22.1 2.9,23 4,23L20,23C21.1,23 22,22.1 22,21L22,5C22,3.9 21.1,3 20,3ZM20,21L4,21L4,8L20,8L20,21Z'
      style={{ fillRule: 'nonzero' }}
    />
    <g transform='matrix(1,0,0,1,0,-3)'>
      <rect x='3.355' y='7.725' width='17.175' height='4.275' />
    </g>
    <g transform='matrix(0.875962,0,0,0.875962,3.28142,2.86906)'>
      <rect x='1.675' y='7.717' width='6.018' height='5.925' />
    </g>
    <g transform='matrix(1.33965,0,0,1.24201,-5.47229,-6.28727)'>
      <rect x='17.292' y='19.172' width='4.708' height='4.708' style={{ fill: 'white' }} />
    </g>
    <g transform='matrix(1.28856,1.18526,-1.06074,1.15319,14.1407,-20.2391)'>
      <path d='M14.782,17.309L14.782,18.876L11.527,16.465L14.782,14.053L14.782,15.621L18.847,15.621L18.847,17.309L14.782,17.309Z' />
    </g>
  </svg>
);

ReservationIcon.displayName = 'ReservationIcon';

export const PhoneIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
      <g transform='matrix(1.05323,0,0,1.07187,-0.653801,-0.847039)'>
        <path
          d='M14,4L16,4L16,6L14,6L14,4ZM13,7L15,7L15,9L13,9L13,7ZM11,4L13,4L13,6L11,6L11,4ZM18,9L16,9L16,7L18,7L18,9ZM19,6L17,6L17,4L19,4L19,6ZM21,9L19,9L19,7L21,7L21,9ZM22,6L20,6L20,4L22,4L22,6ZM14.62,14.38L12.1,16.9C9.6,15.47 7.53,13.4 6.1,10.9L8.62,8.38C8.86,8.14 8.96,7.8 8.9,7.48L8.16,3.8C8.07,3.34 7.66,3 7.18,3L3.03,3C2.47,3 2,3.47 2.03,4.03C2.2,6.92 3.05,9.63 4.43,12C6.01,14.73 8.28,16.99 11,18.57C13.37,19.94 16.08,20.8 18.97,20.97C19.53,21 20,20.53 20,19.97L20,15.82C20,15.34 19.66,14.93 19.2,14.84L15.53,14.11C15.2,14.04 14.86,14.14 14.62,14.38ZM14,10L16,10L16,12L14,12L14,10ZM11,10L13,10L13,12L11,12L11,10ZM19,12L17,12L17,10L19,10L19,12ZM22,12L20,12L20,10L22,10L22,12Z'
          style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
        />
      </g>
    </g>
  </svg>
);

PhoneIcon.displayName = 'PhoneIcon';

export const TipIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g>
      <path
        d='M12,3C11.54,3 11.07,3.04 10.6,3.14C7.84,3.67 5.64,5.9 5.12,8.66C4.64,11.27 5.6,13.67 7.34,15.22C7.77,15.6 8,16.13 8,16.69L8,19C8,20.1 8.9,21 10,21L10.28,21C10.63,21.6 11.26,22 12,22C12.74,22 13.38,21.6 13.72,21L14,21C15.1,21 16,20.1 16,19L16,16.69C16,16.14 16.22,15.6 16.64,15.23C18.09,13.95 19,12.08 19,10C19,6.13 15.87,3 12,3ZM14,19L10,19L10,18L14,18L14,19ZM14,17L10,17L10,16L14,16L14,17ZM12.5,11.41L12.5,14L11.5,14L11.5,11.41L9.67,9.59L10.38,8.88L12,10.5L13.62,8.88L14.33,9.59L12.5,11.41Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

TipIcon.displayName = 'TipIcon';

export const Tip2Icon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
      <path
        d='M17,8L7,8C5.9,8 5,8.9 5,10L5,20C5,21.1 5.9,22 7,22L17,22C18.1,22 19,21.1 19,20L19,10C19,8.9 18.1,8 17,8ZM13,20.5L11,20.5L11,19L13,19L13,20.5ZM13,18L11,18C11,16.5 8.5,15 8.5,13C8.5,11.07 10.07,9.5 12,9.5C13.93,9.5 15.5,11.07 15.5,13C15.5,15 13,16.5 13,18ZM18,6.5L6,6.5C6,5.67 6.67,5 7.5,5L16.5,5C17.33,5 18,5.67 18,6.5ZM17,3.5L7,3.5C7,2.67 7.67,2 8.5,2L15.5,2C16.33,2 17,2.67 17,3.5Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

Tip2Icon.displayName = 'Tip2Icon';

export const Tip3Icon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g>
      <rect x='0' y='0' width='24' height='24' style={{ fill: 'none' }} />
    </g>
    <g transform='matrix(0.998586,0,0,1.08594,0.00868953,-1.57419)'>
      <path
        d='M12,3C11.54,3 11.07,3.04 10.6,3.14C7.84,3.67 5.236,5.914 4.716,8.674C4.236,11.284 5.6,13.67 7.34,15.22C7.77,15.6 8.542,16.28 8.542,16.84L8.574,19C8.574,20.1 8.9,21 10,21L10.28,21C10.63,21.6 11.26,22 12,22C12.74,22 13.38,21.6 13.72,21L14,21C15.1,21 15.54,20.093 15.54,18.993L15.495,16.791C15.495,16.241 16.22,15.6 16.64,15.23C18.09,13.95 19.333,12.057 19.333,9.977C19.333,6.107 15.87,3 12,3ZM14,19L10,19L10,18L14,18L14,19ZM14,17L10,17L10,16L14,16L14,17ZM12.5,11.41L12.5,14L11.5,14L11.5,11.41L9.67,9.59L10.38,8.88L12,10.5L13.62,8.88L14.33,9.59L12.5,11.41Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

Tip3Icon.displayName = 'Tip3Icon';

export const WinterSeasonIcon: React.FC = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 20 20'
    version='1.1'
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g transform='matrix(1,0,0,1,-2,-2)'>
      <path
        d='M22,11L17.83,11L21.07,7.76L19.66,6.34L15,11L13,11L13,9L17.66,4.34L16.24,2.93L13,6.17L13,2L11,2L11,6.17L7.76,2.93L6.34,4.34L11,9L11,11L9,11L4.34,6.34L2.93,7.76L6.17,11L2,11L2,13L6.17,13L2.93,16.24L4.34,17.66L9,13L11,13L11,15L6.34,19.66L7.76,21.07L11,17.83L11,22L13,22L13,17.83L16.24,21.07L17.66,19.66L13,15L13,13L15,13L19.66,17.66L21.07,16.24L17.83,13L22,13L22,11Z'
        style={{ fill: 'rgb(237,28,36)', fillRule: 'nonzero' }}
      />
    </g>
  </svg>
);

WinterSeasonIcon.displayName = 'WinterSeasonIcon';
