import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface HomePageCardContainerLayoutProps extends StandardDivProps<HomePageCardContainerLayoutClassKey> {
  children: React.ReactNode;
}

export type HomePageCardContainerLayoutClassKey = 'root';

const themeSettings = { name: 'HomePageCardContainerLayout' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageCardContainerLayoutClassKey, {}>({
      root: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(260px, 1fr))',
        gridGap: `${theme.spacing(2)}px`,
        width: '100%',
        ...theme.typography.body1,
        [theme.breakpoints.down('md')]: {
          overflowX: 'auto',
        },
      },
    }),
  themeSettings,
);

const HomePageCardContainerLayout = React.forwardRef<HTMLDivElement, HomePageCardContainerLayoutProps>(
  ({ children, className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {children}
      </div>
    );
  },
);

HomePageCardContainerLayout.displayName = 'HomePageCardContainerLayout';

export default withThemeProps(themeSettings)(HomePageCardContainerLayout);
