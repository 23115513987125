import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export type ArticleCardSkeletonProps = StandardDivProps<ArticleCardSkeletonClassKey>;

export type ArticleCardSkeletonClassKey = 'root' | 'container';

const themeSettings = { name: 'ArticleCardSkeleton' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ArticleCardSkeletonClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        width: 260,
        height: 370,
      },
      container: { padding: theme.spacing(2) },
    }),
  themeSettings,
);

const ArticleCardSkeleton = React.forwardRef<HTMLDivElement, ArticleCardSkeletonProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Box display='flex' flexDirection='column' flexGrow={1} className={classes.container}>
          <Skeleton width='100%' height={72} variant='rect' />
          <Skeleton width='100%' height={80} variant='rect' />
        </Box>
        s
        <Skeleton width='100%' height={160} variant='rect' />
      </div>
    );
  },
);

ArticleCardSkeleton.displayName = 'ArticleCardSkeleton';

export default withThemeProps(themeSettings)(ArticleCardSkeleton);
