import React from 'react';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';

export interface ErrorInternalProps extends StandardDivProps<never> {
  error?: any;
}

const ErrorInternal = React.forwardRef<any, ErrorInternalProps>(({ error }, ref) => {
  if (error instanceof Error) {
    console.error('Internal Error', error.message, error);
  } else if (error) {
    console.error('Internal Error', error);
  } else {
    console.error('Internal Error: unknown');
  }

  return <div ref={ref}>Something went wrong...</div>;
});

ErrorInternal.displayName = 'ErrorInternal';

export default ErrorInternal;
