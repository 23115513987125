import { atom, selector } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';
import { HomePageBanner } from './HomePageBanner';

export const homepageMainBannerQuery = selector({
  key: 'homepageMainBannerQuery',
  get: ({ get }) => {
    const initial = get(homepageMainBannerInitialState);
    if (initial !== undefined) return initial;

    return getHomepageMainBanner(get(apiClientState));
  },
});
export const homepageMainBannerInitialState = GlobalStateRegister.register(
  atom<HomePageBanner | null | undefined>({
    key: 'homepageMainBannerInitial',
    default: undefined,
  }),
);

export const getHomepageMainBanner = (apiClient: ApiClient): Promise<HomePageBanner | null> => {
  invariant(apiClient, "getHomepageBanner(...) requires 'apiClient' parameter to be defined.");

  return apiClient.get('/v1/homepage/banner');
};
