import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import HomePageNarrowBestSellerDimensions from './HomePageNarrowBestSellerDimensions';
import HomePageNarrowBestSellerProducts from './HomePageNarrowBestSellerProducts';
import HomePageNarrowFilter from './HomePageNarrowFilter';

export type HomePageNarrowContentProps = StandardDivProps<HomePageNarrowContentClassKey>;

export type HomePageNarrowContentClassKey = 'root' | 'layout' | 'filter';

const themeSettings = { name: 'HomePageNarrowContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<HomePageNarrowContentClassKey, {}>({
      root: {},
      layout: {
        padding: theme.spacing(2, 0),
      },
      filter: {
        paddingBottom: theme.spacing(2),
      },
    }),
  themeSettings,
);

const HomePageNarrowContent = React.forwardRef<HTMLDivElement, HomePageNarrowContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <HomePageNarrowFilter className={classes.filter} />
        <HomePageNarrowBestSellerProducts className={classes.layout} />
        <HomePageNarrowBestSellerDimensions className={classes.layout} />
      </div>
    );
  },
);

HomePageNarrowContent.displayName = 'HomePageNarrowContent';

export default withThemeProps(themeSettings)(HomePageNarrowContent);
